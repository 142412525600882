<script setup lang="ts">
import { ref, Ref } from 'vue';
import ModalComponent from "@/views/components/ModalComponent.vue";
const VITE_ASSET_URL = import.meta.env.VITE_ASSET_URL



const topelaVideoPlayModalTrigger: Ref<any> = ref(null)

// @ts-ignore
function showVideo(event: any) {
    event.preventDefault();
    if (topelaVideoPlayModalTrigger !== null) {
        topelaVideoPlayModalTrigger.value.click()
        const video: any = document.getElementById('modalVideo')
        if (video !== null) {
            video.play()
        }
        
    }
}

function onModalHidden() {
    const video: any = document.getElementById('modalVideo')
    if (video !== null) {
        video.pause()
    }
}

</script>

<template>
    <div class="video-promo-content text-center">
        <a type="button" class="video-popup" style="padding: 0px !important;"  @click="showVideo" >
            <!-- <i class="material-icons icon-large">videocam</i> -->
            <i class="material-icons icon-large" style="font-size: 70px !important; margin-top:5px !important">play_arrow</i>
        </a>
    </div>
    <button ref="topelaVideoPlayModalTrigger" v-show="false" data-bs-toggle="modal" data-bs-target="#topelaVideoPlayerModal"></button>

    <ModalComponent :on-modal-hidden="onModalHidden" :should-add-backdrop="true" id="topelaVideoPlayerModal" :remove-padding="true">
        <template v-slot:content>
            <div>
                <video id="modalVideo" class="video-popup d-flex align-content-center justify-content-center w-100" height="400" controls>
                    <source :src="VITE_ASSET_URL + 'assets/public/assets/video/TOPELA.mp4'" type="video/mp4">
                </video>
            </div>
        </template>
    </ModalComponent>
</template>

<style scoped>
.modal-body {
   padding: "0px !important";
}

</style>